import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ClickOutsideModule } from 'ng-click-outside';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './services/loader.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { BnlawComponent } from './dashboard/bnlawyers/bnlaw.component';
import { BnoperationsComponent } from './dashboard/bnoperations/bnoperations.component';
import { BnipComponent } from './dashboard/bnip/bnip.component';
import { ClientsComponent } from './contacts/clients/clients.component';
import { MattersComponent } from './matters/matters.component';

import { LoginComponent } from './login/login.component';

import { TasksComponent } from './tasks/tasks.component';

import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material';
import { EmailsComponent } from './emails/emails.component';
import { DocumentsComponent } from './documents/documents.component'
import { ContactsComponent } from './contacts/contacts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './services/auth.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { AuthGuardService } from './services/auth-guard.service';


import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ReportsModule } from './reports/reports.module';
import { QuotesComponent } from './quotes/quotes.component';
import { LeadsModule } from './leads/leads.module';

import { Approutes } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { NgxPaginationModule } from 'ngx-pagination';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderInterceptor } from './services/loader.interceptor';
import { ImportComponent } from './contacts/import/import.component';
import { PersonComponent } from './contacts/person/person.component';
import { OrganizationComponent } from './contacts/organization/organization.component';
import { BackgroundVerificationComponent } from './contacts/background-verification/background-verification.component';
import { ViewContactComponent } from './contacts/view-contact/view-contact.component';


import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { CreateMatterComponent } from './matters/create-matter/create-matter.component';
import { MatterDashboardComponent } from './matters/matter-dashboard/matter-dashboard.component';
import { AddTaskComponent } from './tasks/add-task/add-task.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogServiceService } from './confirmation-dialog/confirmation-dialog-service.service';
import { ViewTaskComponent } from './tasks/view-task/view-task.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ReplacePipe } from './services/replace-pie';
import { MatterDashboardLeftComponent } from './matters/matter-dashboard/matter-dashboard-left/matter-dashboard-left.component';
import { MatterDashboardRightComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-dashboard-right.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatterNotesComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-notes/matter-notes.component';
import { MatterTasksComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-tasks/matter-tasks.component';
import { MatterEmailComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-email/matter-email.component';
import { MatterFilesComponent } from './matters/matter-dashboard/matter-dashboard-right/matter-files/matter-files.component';


import { TaskListsComponent } from './tasks/task-lists/task-lists.component';
import { AddTaskListsComponent } from './tasks/task-lists/add-task-lists/add-task-lists.component';

import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { AddCustomFieldsComponent } from './custom-fields/add-custom-fields/add-custom-fields.component';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
const moment = _rollupMoment || _moment;
import {FormControl} from '@angular/forms';
import { ListsModule } from './lists/lists.module'
import { CalenderModule } from './calendar/calender.module';

import { CookieService } from 'ngx-cookie-service';
import { EditClientComponent } from './contacts/clients/edit-client/edit-client.component';
import {MatCheckboxModule} from '@angular/material/checkbox'; 
import { DoneListingsComponent } from './matters/matter-dashboard/matter-dashboard-right/done-listings/done-listings.component';
import {EditTaskComponent} from './matters/matter-dashboard/matter-dashboard-right/done-listings/edit-task/edit-task.component';
import { ViewQuotesComponent } from './quotes/view-quotes/view-quotes.component';
import { LitigationMatterComponent } from './matters/create-matter/litigation-matter/litigation-matter.component';
import { IpMatterComponent } from './matters/create-matter/ip-matter/ip-matter.component';
import { CorporateMatterComponent } from './matters/create-matter/corporate-matter/corporate-matter.component';
import { OtherMatterComponent } from './matters/create-matter/other-matter/other-matter.component'
import { NgSelectModule } from '@ng-select/ng-select';
import { CorporateIncorpoMatterComponent } from './matters/create-matter/corporate-incorpo-matter/corporate-incorpo-matter.component';
import { CorporateOverseasMatterComponent } from './matters/create-matter/corporate-overseas-matter/corporate-overseas-matter.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { InlineTranslationComponent } from './inline-translation/inline-translation.component';
import {MatterWorkflowModule} from './matters/matter-dashboard/matter-dashboard-right/matter-workflow/matter-workflow.module'
import { UserManagementComponent } from './user-management/user-management.component';
import { FilemakerApplicationComponent } from './filemaker-application/filemaker-application.component';
import { ChangePasswordComponent } from './user-management/change-password/change-password.component';
import { environment } from '../environments/environment';
import { RedirectGuard} from './services/redirect.guard.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { CreateUserComponent } from './user-management/create-user/create-user.component';
import { MatterListsComponent } from './lists/matter-lists/matter-lists.component';
import { ChildboxComponent } from './knowledge-base/childbox/childbox.component';
import { CommentsComponent } from './knowledge-base/comments/comments.component';
import { DatacontainerDirective } from './knowledge-base/comments/comments.component';
import { ArticleDetailsComponent } from './knowledge-base/article-details/article-details.component';
import { DashboardCalendarComponent } from './calendar/dashboard-calendar/dashboard-calendar.component';
import { EditorModule } from '@tinymce/tinymce-angular';

import { ChartsModule, ThemeService } from 'ng2-charts';
//import  "chartjs-plugin-labels";
//import 'chart.piecelabel.js';
import { PieChartComponent } from './dashboard/piechart/piechart';
import { CategoryTopicComponent } from './knowledge-base/category-topic/category-topic.component';
import { TruncatePipe } from './services/truncate';
import { ProcessStListComponent } from './tasks/process-st-list/process-st-list.component';
import { AddEditProcessStComponent } from './tasks/process-st-list/add-edit-process-st/add-edit-process-st.component';
import { ViewProcessStComponent } from './tasks/process-st-list/view-process-st/view-process-st.component';
import { ComposemailComponent } from './emails/composemail/composemail.component';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID, //1039361477929-qk9hnoo2e3hr6apq4esk1lm7b81m3hr8.apps.googleusercontent.com
    provider: new GoogleLoginProvider(`${environment.googleloginprovider}`)
  },

]);

export function provideConfig() {
  return config;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export class DatepickerFormatsExample {
  date = new FormControl(moment());

  flightSchedule = {
    date: new Date()
  }
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    BnlawComponent,
    BnoperationsComponent,
    BnipComponent,
    ClientsComponent,
    MattersComponent,
    LoginComponent,
    TasksComponent,
    EmailsComponent,
    DocumentsComponent,
    ContactsComponent,
    KnowledgeBaseComponent,
    ChildboxComponent,
    CommentsComponent,
    ResetPasswordComponent,
    QuotesComponent,
    ForgotPasswordComponent,
    LoaderComponent,
    ImportComponent,
    PersonComponent,
    OrganizationComponent,
    BackgroundVerificationComponent,
    ViewContactComponent,
    CreateMatterComponent,
    MatterDashboardComponent,
    AddTaskComponent,
    ConfirmationDialogComponent,
    ViewTaskComponent,
    ReplacePipe,
    TruncatePipe,
    MatterDashboardLeftComponent,
    MatterDashboardRightComponent,
    MatterNotesComponent,
    MatterTasksComponent,
    MatterEmailComponent,
    MatterFilesComponent,
    TaskListsComponent,
    AddTaskListsComponent,
    CustomFieldsComponent,
    AddCustomFieldsComponent,
    EditClientComponent,
    DoneListingsComponent,
    EditTaskComponent,
    ViewQuotesComponent,
    LitigationMatterComponent,
    IpMatterComponent,
    CorporateMatterComponent,
    OtherMatterComponent,
    CorporateIncorpoMatterComponent,
    CorporateOverseasMatterComponent,
    InlineTranslationComponent,
    UserManagementComponent,
    FilemakerApplicationComponent,
    ChangePasswordComponent,
    NotFoundComponent,
    CreateUserComponent,
    MatterListsComponent,
    DatacontainerDirective,
    ArticleDetailsComponent,
    PieChartComponent,
    CategoryTopicComponent,
    ProcessStListComponent,
    AddEditProcessStComponent,
    ViewProcessStComponent,
    ComposemailComponent,
    

  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ReportsModule,
    LeadsModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    RouterModule.forRoot(Approutes, {useHash: false,onSameUrlNavigation:'reload'}),
    SocialLoginModule,
    NgbModule,
    MatDialogModule,
    AngularEditorModule,
    NgxMaterialTimepickerModule,
    ListsModule,
    NgSelectModule,
    MatCheckboxModule,   
    ModalModule.forRoot(),
    CalenderModule,
    MatProgressBarModule,
    MatterWorkflowModule,
    ClickOutsideModule,
    ChartsModule,
    EditorModule
  ],
  providers: [AuthenticationService,AuthGuardService,CookieService,ThemeService,
				  {
				    provide: HTTP_INTERCEPTORS,
				    useClass: TokenInterceptorService,
				    multi: true,

				  },

          LoaderService,
          RedirectGuard,
          { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
          { provide: LocationStrategy, useClass: HashLocationStrategy},
          { provide: AuthServiceConfig, useFactory: provideConfig},
          {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
          {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
          ConfirmationDialogServiceService
          ],
  entryComponents: [ ConfirmationDialogComponent , ChildboxComponent,DashboardCalendarComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
